const Constants = {

    //BACKEND_URL : "https://api.d01e.gcp.ford.com/nasars/mobileappapi", //  Use for dev
    //"http://localhost:8080",
    //BACKEND_URL : "https://salesapiservicebackup-ot4vtqdpga-uc.a.run.app/nasars/mobileappapi", //  Use for dev
    BACKEND_URL: "https://api.pd01e.gcp.ford.com/nasars/mobileappapi", // Use for prod

    //WEB_RESOURCE : "urn:nasalesmobileappdev:resource:web_nasales:dev", // Use for dev
    WEB_RESOURCE : "urn:nasalesmobileappdev:resource:web_nasales:prod", // Use for prod

    //WEB_CLIENT_ID : "urn:nasalesmobileappdev:clientid:web_nasales:dev", // Use for dev
    WEB_CLIENT_ID : "urn:nasalesmobileappdev:clientid:web_nasales:prod", // Use for prod
    
   // AUTHORIZATION_URL : "https://corpqa.sts.ford.com", // Use for dev
    AUTHORIZATION_URL : "https://corp.sts.ford.com", // Use for Prod
}

export default Constants;