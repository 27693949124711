import * as React from 'react';
import { useState } from "react";
import "./Card.css";
import Card from './Card';
import { CardContent, Grid, Box, Button, SvgIcon, Alert, DialogActions, DialogContent, DialogTitle, TextField, Table, Slide, TableBody } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TransitionProps } from '@mui/material/transitions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import axiosInstance from '../axiosInstance';
import jwtDecode from 'jwt-decode';
import Constants from '../Constants';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import useWindowDimensions from './WindowDimensions';
import axios from 'axios';

const theme = createTheme({
    typography: {
      fontFamily: 'Antenna-Light'
    },
});

const optionMapping = {
    "reportCat": [
        "--", 
        "Stock", 
        "Sales"
    ],
    "reportType": [
        "--",
        "Age Summary",
        "Sales Summary",
        "Full Dealer Ranking",
        "Real Time Sales Summary",
        "Period to Period Analysis",
        "Sales History"
    ],
    "orgType": [
        "--",
        "All"
    ],
    "division": [
        "--",
        "All",
        "Ford",
        "Lincoln",
    ]
};

const deleteReport = async (report : Report) => {

    let cdsid = "";
    let isDealer = "N";
    if(window.sessionStorage.getItem("access_token") !== null){
        let data : any = jwtDecode(window.sessionStorage.getItem("access_token")!);
        cdsid = data["sub"];
        if (cdsid.includes("-")) {
            isDealer = "Y";
        }
    } else {
        return;
    }
    
    let matchConfig = {

        method: 'get',
        maxBodyLength: Infinity, // Temporary solution, i'll just switch the constant to salesbackup2
        url: `${Constants.BACKEND_URL}/api/v1/savedreports/match?division=${optionMapping["division"][Number(report.division)]}&orgType=${optionMapping["orgType"][Number(report.orgType)]}&reportType=${optionMapping["reportType"][Number(report.reportType)]}&reportCat=${optionMapping["reportCat"][Number(report.reportCat)]}`,
        headers: { 
          'Authorization': `Bearer ${window.sessionStorage.getItem("access_token")}`
        }
    };

    let matchResponse = await axiosInstance.request(matchConfig);
    
    // let deleteConfig = {
    //     method: 'post',
    //     maxBodyLength: Infinity,
    //     url: `${Constants.BACKEND_URL}/api/v1/savedreports/delete`,
    //     headers: { 
    //         'Authorization': `Bearer ${window.sessionStorage.getItem("access_token")}`
    //     }
    //   };

      axios.post(`${Constants.BACKEND_URL}/api/v1/savedreports/delete`, {
        reportId: matchResponse.data.toString(),
        cdsid: cdsid,
        dealer: isDealer
      }, { headers: { 
        'Authorization': `Bearer ${window.sessionStorage.getItem("access_token")}`
      }})
      .then(function (response) {
        console.log(response);
      })
    
      
    //let _ = await axiosInstance.request(deleteConfig);

}

const insertNewReport = async (report : Report) => {

    let cdsid = "";
    let isDealer = "N";
    if(window.sessionStorage.getItem("access_token") !== null){
        let data : any = jwtDecode(window.sessionStorage.getItem("access_token")!);
        cdsid = data["sub"];
        if (cdsid.includes("-")) {
            isDealer = "Y";
        }
    } else {
        return;
    }

    let matchConfig = {

        method: 'get',
        maxBodyLength: Infinity,
        url: `${Constants.BACKEND_URL}/api/v1/savedreports/match?division=${optionMapping["division"][Number(report.division)]}&orgType=${optionMapping["orgType"][Number(report.orgType)]}&reportType=${optionMapping["reportType"][Number(report.reportType)]}&reportCat=${optionMapping["reportCat"][Number(report.reportCat)]}`,
        headers: { 
          'Authorization': `Bearer ${window.sessionStorage.getItem("access_token")}`
        }
    };

    let matchResponse = await axiosInstance.request(matchConfig);

    // let insertConfig = {
    //     method: 'post',
    //     maxBodyLength: Infinity,
    //     url: `${Constants.BACKEND_URL}/api/v1/savedreports/insert`,
    //     headers: { 
    //       'Authorization': `Bearer ${window.sessionStorage.getItem("access_token")}`
    //     },
    //     body:{
    //         dealer:isDealer,
    //         cdsid:cdsid,
    //         reportID: matchResponse.data
    //     }
    // };

    axios.post(`${Constants.BACKEND_URL}/api/v1/savedreports/insert`, {
        dealer:isDealer,
        cdsid:cdsid,
        reportId: matchResponse.data.toString(),
        reportName: "null"
      }, { headers: { 
        'Authorization': `Bearer ${window.sessionStorage.getItem("access_token")}`
      }})
      .then(function (response) {
        console.log(response);
      })
   // let _ = await axiosInstance.request(insertConfig);

}

type GenReportCardProps = {
    setReportFunction: (report : string | undefined) => void
    setDivisionFunction: (division : string) => void
    clearOutput : () => void
}

class Report {  
    constructor(reportCat: String, reportType: String, orgType: String, division: String, reportName: String){
        this.reportCat = reportCat;
        this.reportType = reportType;
        this.orgType = orgType;
        this.division = division;
        this.reportName = reportName;
    }
    reportCat: String;
    reportType: String;
    orgType: String;
    division: String;
    reportName: String;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const GenReportCard = (props: GenReportCardProps) => {

    const { height, width } = useWindowDimensions();
    const [reportCat, setReportCat] = useState<String>("0");
    const [reportType, setReportType] = useState<String>("0");
    const [orgType, setOrgType] = useState<String>("1");
    const [division, setDivision] = useState<String>("1");
    const [reportName, setReportName] = useState("");
    const [modalData, setModalData] = React.useState({open: false});
    const [modalName, setModalName] = React.useState({open: false});
    const [saveError, setSaveError] = useState(false);
    const [invalidReportError, setInvalidReportError] = useState(false);
    const [newReportName, setNewReportName] = useState("");
    const [nameTooLong, setNameTooLong] = useState(false);
    const [currentEditingReport, setCurrentEditingReport] = useState<Report>();

    const [savedReports, setSavedReports] = useState<Array<Report>>([]);

    const [savedReportButton, setSavedReportButton] = useState<boolean>(true);

    React.useEffect(()=>{
        if(savedReportButton) return;
        setTimeout(()=>{
            setSavedReportButton(true);
        }, 500);
    }, [savedReportButton]);


    const handleClose = () => {
        setModalData({open: false});
    }

    const handleModalNameClose = () => {
        setModalName({open: false});
    }

    const handleModalNameOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setModalName({open: true});
    }

    const handleClickOpen = () => {
        
        let cdsid = "";
        
        if(window.sessionStorage.getItem("access_token") !== null){
            let data : any = jwtDecode(window.sessionStorage.getItem("access_token")!);
            cdsid = data["sub"];
        } else {
            return;
        }
    
            
        // var tokenRequestConfig = {
        //     method: 'post',
        //     maxBodyLength: Infinity,
        //     url: `${Constants.BACKEND_URL}/api/v1/savedreports/view`,
        //     headers: { 
        //         'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
        //     },
        //     body:{
        //         cdsid:cdsid
        //     }
        // };
    
        // Get request to adfs server to receive authenticated credentials
        axios.post(`${Constants.BACKEND_URL}/api/v1/savedreports/view`, {
            cdsid: cdsid
          },   
         { headers: { 
            'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
        }
    })
    .then((response) => {

            var newMap: any;

            const map : any = {
                "reportCat": {
                    "Stock": 1,
                    "Sales": 2,
                },
                "reportType": {
                    "Age Summary": 1,
                    "Sales Summary": 2,
                    "Full Dealer Ranking": 3,
                    "Real Time Sales Summary": 4,
                    "Period to Period Analysis": 5,
                    "Sales History": 6
                },
                "orgType":{
                    "All": 1,
                },
                "division":{
                    "All": 1,
                    "Ford": 2,
                    "Lincoln": 3,
                }
            }

            let reportList : Report[] = [];

            for(let i = 0;i<response.data["reports"].length;i++){
                let newReport = new Report(
                    map['reportCat'][response.data["reports"][i]['reportCat']],
                    map['reportType'][response.data["reports"][i]['reportType']], 
                    map['orgType'][response.data["reports"][i]['orgType']], 
                    map['division'][response.data["reports"][i]['division']],
                    response.data['reports'][i]['reportName']
                );

                if(validReport(newReport)) {
                    reportList.push(newReport);
                }

            }

            setSavedReports(reportList);

        }).then(() => {
            setModalData({open: true});
        });


    };

    const handleGo = () => {
        if (reportCat == "1") { // stock
            if (reportType == "1") { // stock age
                props.setReportFunction("stockAge");
            } else {
                props.setReportFunction("");
            }
        } else if (reportCat == "2") { // sales
            if (reportType == "2") { // sales summary
                props.setReportFunction("salesSummary");
            } else if (reportType == "3") { // full dealer ranking
                props.setReportFunction("fullDealerRanking");
            } else if (reportType == "4") { // real time sales
                props.setReportFunction("realTimeSales");
            } else if (reportType == "5") { // period to period
                props.setReportFunction("periodToPeriod");
            } else if (reportType == "6") { // sales history
                props.setReportFunction("salesHistory");
            } else {
                props.setReportFunction("");
            }
        } else {
            props.setReportFunction("");
        }
    }

    const handleOnSetReport = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if(e.currentTarget.getAttribute("data-button-key") === null) return;
        let index = parseInt(e.currentTarget.getAttribute("data-button-key")!);
        setReportCat(savedReports[index].reportCat);
        setReportType(savedReports[index].reportType);
        setOrgType(savedReports[index].orgType);
        setDivision(savedReports[index].division);
        if (savedReports[index].division == "1") {
            props.setDivisionFunction("All");
        } else if (savedReports[index].division == "2") {
            props.setDivisionFunction("Ford");
        } else if (savedReports[index].division == "3") {
            props.setDivisionFunction("Lincoln");
        }
        props.clearOutput();
        handleClose();
    }

    const saveReportButtonCallback = async () => {

        setSavedReportButton(false);
        let newReport = new Report(reportCat, reportType, orgType, division, reportName);
         

        let cdsid = "";
        var count = 0;
        if(window.sessionStorage.getItem("access_token") !== null){

            let data : any = jwtDecode(window.sessionStorage.getItem("access_token")!);
            cdsid = data["sub"];

            // let config = {
            //     method: 'post',
            //     maxBodyLength: Infinity,
            //     url: `${Constants.BACKEND_URL}/api/v1/savedreports/findAll?cdsid=${cdsid}`,
            //     headers: { 
            //       'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
            //     },
            //   };
              axios.post(`${Constants.BACKEND_URL}/api/v1/savedreports/findAll`, {
                cdsid: cdsid
              },{headers: { 
                'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
              }})
              .then((response) => {

                count = response.data;

                if (validReport(newReport) && count < 6) {
                    setSaveError(false);
                    setInvalidReportError(false);
                    insertNewReport(newReport);
                    
                } else if (!validReport(newReport)) {
                    setInvalidReportError(true);
                } else {
                    // alert("Maximum saved reports reached, please delete a saved report before adding more.");
                    setSaveError(true);
                }


              });
              
        } 

    }

    const renameReport = async() => {
        if (currentEditingReport != undefined) {

            let reportToEdit : Report = currentEditingReport!;

            let division = optionMapping["division"][Number(reportToEdit.division)];
            let orgType = optionMapping["orgType"][Number(reportToEdit.orgType)];
            let reportType = optionMapping["reportType"][Number(reportToEdit.reportType)];
            let reportCat = optionMapping["reportCat"][Number(reportToEdit.reportCat)];
            
            console.log(`${Number(reportToEdit.division)} ${Number(reportToEdit.orgType)} ${Number(reportToEdit.reportType)} ${Number(reportToEdit.reportCat)}`);
            console.log(`${division} ${orgType} ${reportType} ${reportCat}`);
            
            let reportIdConfig = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/savedreports/match?division=${division}&orgType=${orgType}&reportType=${reportType}&reportCat=${reportCat}`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                },
            };

            let reportIdConfigResponse = await axiosInstance.request(reportIdConfig);

            if(window.sessionStorage.getItem("access_token") == null) { return; }

            let data : any = jwtDecode(window.sessionStorage.getItem("access_token")!);
            let cdsid = data["sub"];
            let isDealer = "N";
            if (cdsid.includes("-")) {
                isDealer = "Y";
            }
                
            console.log(reportIdConfigResponse.data);
            // let config = {
            //     method: 'post',
            //     maxBodyLength: Infinity,
            //     url: `${Constants.BACKEND_URL}/api/v1/savedreports/update`,
            //     headers: { 
            //         'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
            //     },
            // };

            axios.post(`${Constants.BACKEND_URL}/api/v1/savedreports/update`, {
                reportId: reportIdConfigResponse.data.toString(),
                cdsid: cdsid,
                dealer: isDealer,
                reportName : newReportName
              }, { headers: { 
                'Authorization': `Bearer ${window.sessionStorage.getItem("access_token")}`
              }})
              .then(function (response) {
                console.log(response);
              })
                
           // axiosInstance.request(config);
                
        } else {
            // give some sort of error?
        }
    }
    
    const getNumberOfReports = () => {
        let cdsid = "";
        var count = 0;
        if(window.sessionStorage.getItem("access_token") !== null){
            let data : any = jwtDecode(window.sessionStorage.getItem("access_token")!);
            cdsid = data["sub"];

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/savedreports/findAll?cdsid=${cdsid}`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                },
              };
              
              axiosInstance.request(config).then((response) => {
                count = response.data;
              });
              
        } 
        return count;
    }

    function containsReport(obj: Report, list: Array<Report>) {
        var i;
        for (i = 0; i < list.length; i++) {
            if(list[i].division === obj.division &&
                list[i].orgType === obj.orgType &&
                list[i].reportType === obj.reportType &&
                list[i].reportCat === obj.reportCat
            ) return true;
        }
        return false;
    }

    function validReport(obj: Report){
        if(obj.division === "0" ||
            obj.orgType === "0" ||
            obj.reportCat === "0" ||
            obj.reportType === "0"
        ) return false;
        return true;
    }

    return(
        <>
    <Card title="Generate Report">
            <CardContent className="body" sx={{margin: 0}}>
                <Grid container spacing={3} justifyContent="center" sx={{margin: 0}} style={{paddingRight: 25}}>
                    <Grid xs={6} sm={6} md={6} style={{paddingRight: 10}}>                   
                        <Box sx={{ minWidth: 120 }}>
                            <ThemeProvider theme={theme}>
                                <FormControl id="reportCategory" fullWidth>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Report Category
                                        </InputLabel>
                                    <NativeSelect
                                        required
                                        value={reportCat}
                                        inputProps={{
                                            name: 'reportCat',
                                            id: 'uncontrolled-native',
                                        }}
                                        onChange = {(event) =>{
                                            setReportCat(event.target.value);
                                            setReportType('0');
                                        }}
                                    >
                                        <option id='0' value={"0"}>--</option>
                                        <option id='1' value={'1'}>Stock</option>
                                        <option id='2' value={'2'}>Sales</option>
                                    </NativeSelect>
                                </FormControl>
                            </ThemeProvider>
                        </Box>
                    </Grid>
                    <Grid xs={6} sm={6} md={6}>                
                        <Box sx={{ minWidth: 120 }}>
                            <ThemeProvider theme={theme}>
                                <FormControl id="reportType" fullWidth>
                                    
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Report Type
                                        </InputLabel>
                                    
                                    <NativeSelect
                                        value={reportType}
                                        inputProps={{
                                            name: 'reportType',
                                            id: 'uncontrolled-native',
                                        }}
                                        onChange = {(event) =>{
                                            setReportType(event.target.value)
                                        }}
                                    >
                                        <option id='0' value={"0"}>--</option>
                                        {reportCat == '1' && ( //report types for stock cateogry
                                            <>
                                            <option id='1' value={'1'}>Age Summary</option>
                                            </>
                                        )}
                                        {reportCat == '2' && ( //report types for sales category
                                            <>
                                            <option id='2' value={'2'}>Sales Summary</option>
                                            <option id='3' value={'3'}>Full Dealer Ranking</option>
                                            <option id='4' value={'4'}>Real Time Sales Summary</option>
                                            <option id='5' value={'5'}>Period to Period Analysis</option>
                                            <option id='6' value={'6'}>Sales History</option>
                                            </>
                                        )}
                                    </NativeSelect>
                                </FormControl>
                            </ThemeProvider>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent="center" sx={{margin: 0}} style={{marginBottom: -10, paddingTop: 5, paddingRight: 25}}>
                    <Grid xs={6} sm={6} md={6} style={{paddingRight: 10}}>                   
                        <Box sx={{ minWidth: 120 }}>
                            <ThemeProvider theme={theme}>
                                <FormControl id='orgType' fullWidth>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Organization Type
                                        </InputLabel>
                                    <NativeSelect
                                        value={orgType}
                                        inputProps={{
                                            name: 'orgType',
                                            id: 'uncontrolled-native',
                                        }}
                                        onChange = {(value) =>{
                                            setOrgType(value.target.value)
                                        }}
                                    >
                                        {/* <option id='0' value={"0"}>--</option> */}
                                        <option id='1' value={"1"}>All</option>
                                        {/* <option id='2' value={"2"}>CVC</option>
                                        <option id='3' value={"3"}>FDAF</option> */}
                                    </NativeSelect>
                                </FormControl>
                            </ThemeProvider>
                        </Box>
                    </Grid>
                    <Grid xs={6} sm={6} md={6}>                   
                        <Box sx={{ minWidth: 120 }}>
                           <ThemeProvider theme={theme}> 
                                <FormControl id='division' fullWidth>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                            Division
                                        </InputLabel>
                                    <NativeSelect
                                        value={division}
                                        inputProps={{
                                            name: 'division',
                                            id: 'uncontrolled-native',
                                        }}
                                        onChange = {(value) =>{
                                            setDivision(value.target.value)
                                            if (value.target.value == "1") {
                                                props.setDivisionFunction("All");
                                            } else if (value.target.value == "2") {
                                                props.setDivisionFunction("Ford");
                                            } else if (value.target.value == "3") {
                                                props.setDivisionFunction("Lincoln");
                                            }
                                        }}
                                    >
                                        {/* <option id='0' value={"0"}>--</option> */}
                                        {
                                            reportType == "4" ? 
                                            <>
                                                <option id='2' value={"2"}>Ford</option>
                                                <option id='3' value={"3"}>Lincoln</option>
                                            </>
                                            : 
                                            <>
                                                <option id='1' value={"1"}>All</option>
                                                <option id='2' value={"2"}>Ford</option>
                                                <option id='3' value={"3"}>Lincoln</option>
                                            </>
                                        }
                                        
                                    </NativeSelect>
                                </FormControl>
                            </ThemeProvider>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>

            <Table style={{marginBottom: 5}}>
            <tbody>
                <tr>
                    <td>
                        <Button
                            variant="outlined">
                            <div className="hyperlink" onClick={saveReportButtonCallback}>
                                Save Report
                            </div>
                        </Button>
                    </td>

                    <td>
                        <Button onClick={(savedReportButton)?handleClickOpen:()=>{}} 
                            variant="outlined" 
                            style={{flex: .4, justifyContent:'center'}}>
                            <div className="hyperlink">
                                View Saved
                            </div>
                        </Button>
                    </td>

                    <td>
                        <div className="hyperlink">
                            <IconButton onClick={() => {
                                handleGo();
                                }}
                                style={{flex: .1, justifyContent:'center'}}>
                                    <SvgIcon id="gobutton" style={{overflow: "visible", paddingRight: 2, paddingBottom: 2}}>
                                        <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                        </svg>
                                    </SvgIcon>
                            </IconButton>
                        </div>
                    </td>
                </tr>
            </tbody>
            </Table>
                {
                    saveError ?
                    <ThemeProvider theme={theme}>
                        <Alert severity="error">You have saved the maximum number of reports, please delete a report before saving more.</Alert>
                    </ThemeProvider>
                    :
                    <></>
                }
                {
                    invalidReportError ?
                    <ThemeProvider theme={theme}>
                        <Alert severity="error">Please make sure you are trying to save a valid report.</Alert>
                    </ThemeProvider>
                    :
                    <></>
                }

    </Card>
    <Dialog fullScreen open={modalData.open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#1e4566' }}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <ThemeProvider theme={theme}>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Saved Reports
                    </Typography>
                </ThemeProvider>
            </Toolbar>
        </AppBar>

        <TableContainer component ={Paper}>
            <Table sx={{width: 375}} aria-label="simple table">
                <TableBody>
                    {
                        savedReports.map((item, index) => {
                            return <React.Fragment key={index}>
                                        <Button variant="text" onClick={handleOnSetReport} data-button-key={index}>
                                            <TableRow style ={{paddingTop: 3, width: "100%"}}>
                                                <TableCell>
                                                    <div className = "thin-allcaps" style={{textAlign: 'center'}}>
                                                        {index + 1}
                                                    </div>
                                                </TableCell>                                                
                                                <TableCell style={{paddingRight:0, paddingLeft: 0}}>
                                                    {/* <div className = 'extranumbers' style={{display: "grid", justifyContent: 'flex', flexWrap: 'wrap', wordWrap: 'break-word', flexShrink: 1}}> {item.reportName} </div> */}
                                                    <div className = 'extranumbers' style={{maxWidth: 600, textOverflow: "ellipsis", overflow: "hidden"}}>
                                                        {item.reportName.includes(" ") || item.reportName.length < 15 ? item.reportName : (item.reportName.substring(0, 12) + "...")}
                                                    </div>
                                                    <div className = "thin-allcaps">
                                                        <b>Report Category:</b> {optionMapping['reportCat'][parseInt(item.reportCat as string)]} <br />
                                                        <b>Report Type:</b> {optionMapping['reportType'][parseInt(item.reportType as string)]} <br />
                                                        <b>Org Type:</b> {optionMapping['orgType'][parseInt(item.orgType as string)]} <br />
                                                        <b>Division:</b> {optionMapping['division'][parseInt(item.division as string)]}
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{verticalAlign: 'top'}}>
                                                    <Button variant="outlined" 
                                                        sx={{":hover": {bgcolor: "transparent", backgroundColor: '#F4ECEC'}}} 
                                                        style={{flex: .4, display: 'flex', marginLeft: "auto", justifyContent: 'center', borderColor: '#F14848', }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteReport(item).then(() => {
                                                                setSaveError(false); 
                                                            }).then(() => {
                                                                setModalData({open: false})
                                                            });

                                                        }}>
                                                            <div className="hyperlink" style={{color: '#F14848'}}>
                                                                Delete
                                                            </div>
                                                    </Button>

                                                    <Button variant="outlined" 
                                                        sx={{":hover": {bgcolor: "transparent", backgroundColor: 'ECECF4'}}} 
                                                        style={{flex: .4, display: 'flex', marginLeft: "auto", justifyContent: 'center', marginTop: 10}} onClick={(e) => {
                                                            setNewReportName("");
                                                            handleModalNameOpen(e);
                                                            setCurrentEditingReport(item);
                                                        }}>

                                                        <div className="hyperlink">
                                                            Rename
                                                        </div>
                                                    </Button>
                                                    
                                                </TableCell>
                                            </TableRow>
                                        </Button>
                                    </React.Fragment>;
                            }) // end return
                    }
                </TableBody>
            </Table>
        </TableContainer>
                                        
    </Dialog>

    <Dialog style={{alignItems: 'stretch'}} fullWidth={ true } maxWidth={"md"} open={modalName.open} onClose={handleModalNameClose} 
        PaperProps={{style: { borderRadius: 10 }}}>

        <ThemeProvider theme={theme}>
            <DialogTitle style={{marginBottom: -20}}>Rename Report</DialogTitle>
            <DialogContent>
                
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                fullWidth
                variant="standard"
                style={{marginBottom: -10}}
                onChange={(e) => {
                    if (e.target.value.length <= 50) {
                        setNameTooLong(false);
                        setNewReportName(e.target.value);
                    } else {
                        setNameTooLong(true);
                    }
                }}
            />
            {
                nameTooLong ?
                <ThemeProvider theme={theme}>
                    <Alert severity="error">Report name cannot be greater than 50 characters.</Alert>
                </ThemeProvider>
                :
                <></>
            }
            </DialogContent>
        </ThemeProvider>
        <DialogActions>
            <div style={{justifyContent: 'left', paddingLeft: 15, paddingBottom: 5}}>
                <Button variant='outlined'  sx={{":hover": {bgcolor: "transparent", backgroundColor: '#F4ECEC'}}} style={{color: '#F14848', borderColor: '#F14848'}} onClick={() => {
                    setModalName({open: false});
                }}>
                    Cancel
                </Button>
            </div>
            <div style={{display: "flex", marginLeft: "auto", paddingRight: 15, paddingBottom: 5}}>

                <Button type='submit' variant='outlined' onClick={() => {
                    setModalName({open: false});
                    setModalData({open: false});
                    renameReport();
                    handleModalNameClose();
                }}>
                    Save
                </Button>
            </div>

        </DialogActions>

      </Dialog>
      
    </>
    );
}

export default GenReportCard;